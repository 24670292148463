import React, { Component } from 'react';
import './Account.css'
import {
    Card,
    Descriptions,
    Row,
    Col,
    Button,
    Modal,
    Form,
    Input,

} from 'antd'
import { Link, } from 'react-router-dom';
import AccountHeader from './AccountHeader';
import { GET_BIND_APARTMENT, GET_USER_STAT_URL, GET_DEPT_LIST, GET_UNIT_LIST, get_data_token, BIND_APARTMENT, CANCELBIND_APARTMENT, GET_PICK_LIST } from '../../config/network'
import { openErrorBox, ModalBox, openModalBox, showErrorMessage } from '../MessageBox'
import { FormattedMessage, } from 'react-intl';
import { Table, } from 'reactstrap';

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            memberinfo: {},
            memberprofile: {},
            statusdetail: {},
            isProfilecompleted: '',
            isEmailVerified: '',
            hasBindAddress: '',
            transactions: [],
            deptzipcode: '',
            bindzipcodevisible: false,
            selectDeptvisible: false,
            ModalText: 'Please input your zipcode first',
            deptlist: [],//apartment list
            unitlist: [],//unit list
            selecteddept: '',
            selectedunit: '',
            records: [],
            apartments: [],
            load: false,
            loadapartment: false,
            loadunit: false,
            loadunitf: false,
            loadzipcode: false,
            loaddepartment: false,
            profileready: false,
            //
            cancelbinddept: '',
            cancelbindzipcodevisible: false,
            loadcancel: false,
        };
        this.showModal = this.showModal.bind(this);
        this.cancelshowModal = this.cancelshowModal.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUnitChange = this.handleUnitChange.bind(this);
        this.handleDeptSubmit = this.handleDeptSubmit.bind(this);
        this.handlezipcodeSubmit = this.handlezipcodeSubmit.bind(this);
        this.handlezipcodeChange = this.handlezipcodeChange.bind(this);
        this.getApartment = this.getApartment.bind(this);
        this.getPickList = this.getPickList.bind(this);
        //
        this.handlecanceldeptChange = this.handlecanceldeptChange.bind(this);
        this.handlecancelbindSubmit = this.handlecancelbindSubmit.bind(this);

    }
    //
    cancelshowModal = () => {
        this.setState({
            //ModalText: 'Please input your departmentID',
            ModalText: '',
            cancelbinddept: '',
            cancelbindzipcodevisible: true,
            loadcancel: false,
            profileready: false,
        });
    }
    //
    showModal = () => {

        if (this.state.memberprofile.firstName.length === 0 || this.state.memberprofile.lastName.length === 0) {
            // console.log(this.state.memberprofile.firstName.length);
            // openErrorBox.call(this,{content:window.appLocale.messages['page.profile.err']||'Please complete your profile before subscibe to your apartment'});
            showErrorMessage.call(this, 'Please complete your profile before subscibe to your apartment:');
            // console.log(this.state.memberprofile.firstName.length);
            return
        }

        this.setState({
            //ModalText: 'Please input your zipcode first',
            profileready: true,
            ModalText: '',
            deptzipcode: '',
            bindzipcodevisible: true,
            loadzipcode: false,
        });

    };
    //cancel输入事件
    handlecanceldeptChange(event) {
        this.setState({ cancelbinddept: event.target.value, loadcancel: false, });
    }
    //
    rendercancelbind() {
        if (this.state.loadcancel)
            return <h5>Loading...</h5>;

        return (
            <Modal
                title="Input your bound Apartment ID"
                visible={this.state.cancelbindzipcodevisible}
                onOk={this.handlecancelbindSubmit}
                onCancel={this.handleCancel}
            >
                <Form >
                    <Form.Item label="Apartmentid">
                        <Input type="text" value={this.state.cancelbinddept} onChange={this.handlecanceldeptChange} />
                    </Form.Item>
                </Form>
                <p>{this.state.ModalText}</p>
            </Modal>
        )
    }
    //cancelbind界面 submit事件
    handlecancelbindSubmit(event) {
        let deptid = this.state.cancelbinddept;
        if (deptid === undefined) {
            return;
        }
        if (deptid === null) {
            return;
        }
        if (deptid === "") {
            this.setState({
                ModalText: 'Error apartmentId,please input again',
                loadcancel: false,
            })
            return;
        }
        get_data_token(CANCELBIND_APARTMENT, { apartmentId: deptid })
            .then(
                data => {

                    this.getApartment();
                    this.setState({ cancelbindzipcodevisible: false, loadcancel: false, });
                    return;

                })
            .catch(
                err => showErrorMessage.call(this, 'Cancel Bind apartment data error:' + err)
            )
        //
        this.setState({
            ModalText: 'No such apartmentId!',
            loadcancel: false,
        })

    }
    //zipcode界面 OK事件
    handleOk = () => {
        this.setState({
            ModalText: 'The modal will be closed after two seconds',
            loadzipcode: false,
            //loaddepartment:true,
        });
        setTimeout(() => {
            this.setState({
                deptzipcode: '',
                bindzipcodevisible: false,
                bindDeptvisible: false,
                selectDeptvisible: false,
                cancelbindzipcodevisible: false,
                loaddepartment: false,
                loadzipcode: false,
                loadcancel: false,

            });
        }, 2000);
    };
    //zipcode界面 Cancel事件
    handleCancel = () => {
        // console.log('Clicked cancel button');
        this.setState({
            deptzipcode: '',
            bindzipcodevisible: false,
            selectDeptvisible: false,
            cancelbindzipcodevisible: false,
            loadzipcode: false,
            loaddepartment: false,
            loadcancel: false,
            profileready: false,
        });
    };
    //   
     renderunitn(apartid) {
        // if (this.state.selecteddept === '')
        //     return;
        get_data_token(GET_UNIT_LIST, { apartmentId: apartid })
            .then(
                data => {
                    if (data.unitList.length === 0) {
                        this.setState({
                            ModalText: 'Error Unit',
                            loadzipcode: false,
                        })
                        return;
                    }
                    else {
                        let unitlistapi = data.unitList.map((record, index) => {


                            if (index === 0) {
                                this.setState({ selectedunit: record.unitId, });

                            }
                            return { value: record.unitId, key: record.unitName }
                        });
                        this.setState({
                            ModalText: '',
                            unitlist: unitlistapi,
                            bindzipcodevisible: false,
                            loadzipcode: false,
                            selectDeptvisible: true,
                            loaddepartment: false,
                            loadunitf: true,
                        });
                    }
                }
            )
            .catch(
                err => showErrorMessage.call(this, 'Get department data error:' + err)
            )
        //
    }
    //apartment选择事件
    handleChange(event) {
        this.renderunitn(event.target.value);
        this.setState({ selecteddept: event.target.value, loaddepartment: false });
        
    }
    //unit选择事件
    handleUnitChange(event) {
        this.setState({ selectedunit: event.target.value, loadunit: false, });
    }
    //
    handlezipcodeChange(event) {
        this.setState({ deptzipcode: event.target.value, loadzipcode: false, });
    }
    //
    renderZipcode() {

        if (this.state.profileready) {
            if (this.state.loadzipcode)
                return <h5>Loading...</h5>;

            return (
                <Modal
                    title="Select your zipcode"
                    visible={this.state.bindzipcodevisible}
                    //onOk={this.handleOk}
                    onOk={this.handlezipcodeSubmit}
                    onCancel={this.handleCancel}
                >
                    <Form >
                        <Form.Item label="zipcode">
                            <Input type="text" value={this.state.deptzipcode} onChange={this.handlezipcodeChange} />
                        </Form.Item>
                    </Form>
                    <p>{this.state.ModalText}</p>
                </Modal>
            )
        }
    }

    //
    renderunit() {
        if (this.state.loadunitf)
            return;
        if (this.state.selecteddept === '')
            return;
        get_data_token(GET_UNIT_LIST, { apartmentId: this.state.selecteddept })
            .then(
                data => {
                    if (data.unitList.length === 0) {
                        this.setState({
                            ModalText: 'Error Unit',
                            loadzipcode: false,
                        })
                        return;
                    }
                    else {
                        let unitlistapi = data.unitList.map((record, index) => {


                            if (index === 0) {
                                this.setState({ selectedunit: record.unitId, });

                            }
                            return { value: record.unitId, key: record.unitName }
                        });
                        this.setState({
                            ModalText: '',
                            unitlist: unitlistapi,
                            bindzipcodevisible: false,
                            loadzipcode: false,
                            selectDeptvisible: true,
                            loaddepartment: false,
                            loadunitf: true,
                        });
                    }
                }
            )
            .catch(
                err => showErrorMessage.call(this, 'Get department data error:' + err)
            )
        //
    }
    renderDepartment() {
        if (this.state.loaddepartment)
            return <h5>Loading...</h5>;
        const deptlist = this.state.deptlist;
        let Deptoptions = deptlist.map((item) =>
            //    <option value={item.apartmentId}>{item.apartmentName}</option>
            <option value={item.value}>{item.key}</option>
        );
        this.renderunit();
        const unitlist = this.state.unitlist;
        let Unitoptions = unitlist.map((item) =>

            <option value={item.value}>{item.key}</option>
        );
        //let selectDeptvisible=this.state.selectDeptvisible;
        return (
            <Modal
                title="Select Apartment"
                visible={this.state.selectDeptvisible}
                //visible={selectDeptvisible}
                //onOk={this.handleOk}
                onOk={this.handleDeptSubmit}
                onCancel={this.handleCancel}
            >
                <Form >
                    <Form.Item label="Apartment">

                        <select value={this.state.selecteddept} onChange={this.handleChange}>
                            {Deptoptions}

                        </select>
                    </Form.Item>
                    <Form.Item label="Unit">

                        <select value={this.state.selectedunit} onChange={this.handleUnitChange}>
                            {Unitoptions}

                        </select>
                    </Form.Item>
                </Form>

                <p>{this.state.ModalText}</p>
            </Modal>
        )
    }
    //
    renderApartments() {
        //let approvestatus;
        if (this.state.loadapartment)
            return <h5>Loading...</h5>;
        if (this.state.apartments.length === 0)
            return <p>You have not linked your account with any locker yet. please bind your account with a locker.</p>;
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Apartment ID</th>
                        <th>Locker Address</th>
                        <th>Approve Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.apartments.map(record =>
                            <tr>
                                <td>{record.apartmentId}</td>
                                <td>{record.apartmentName}</td>
                                <td>{record.approveStatus === "1" ? "approved" : "to be approved"}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        )
    }
    //
    renderRecords() {
        if (this.state.load)
            return <h5>Loading...</h5>;
        if (this.state.records.length === 0)
            return <p>You have no package.</p>;
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Store Id</th>
                        <th>Cabinet Id</th>
                        <th>Pickup Code</th>
                        <th>Store Time</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.records.map(record =>
                            <tr>
                                <td>{record.storeId}</td>
                                <td>{record.cabinetId}</td>
                                <td>{record.pickCode}</td>
                                <td>{record.storeTime}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        )
    }

    //zipcode界面 submit事件
    handlezipcodeSubmit(event) {
        let deptzipcode = this.state.deptzipcode;
        //let selecteddept;
        if (deptzipcode === undefined) {
            return;
        }
        if (deptzipcode === null) {
            return;
        }
        if (deptzipcode === "") {
            this.setState({
                ModalText: 'Error zipcode,please input zipcode again',
                loadzipcode: false,
            })
            return;
        }
        get_data_token(GET_DEPT_LIST, { zipcode: deptzipcode })
            .then(

                data => {
                    if (data.apartmentList.length === 0) {
                        this.setState({
                            ModalText: 'Error zipcode,please input zipcode again',
                            loadzipcode: false,
                        })
                        return;
                    }
                    else
                    //
                    {

                        let departmentlistapi = data.apartmentList.map((record, index) => {


                            if (index === 0) {

                                this.setState({ selecteddept: record.apartmentId, });

                            }
                            return { value: record.apartmentId, key: record.apartmentName }
                        });
                        //
                        /*
 
                        */
                        //
                        this.setState({
                            ModalText: '',
                            deptlist: departmentlistapi,
                            bindzipcodevisible: false,
                            loadzipcode: false,
                            selectDeptvisible: true,
                            loaddepartment: false,
                            loadunitf: false,
                        });
                        //

                    }
                    //

                })
            .catch(
                err => showErrorMessage.call(this, 'Get department data error:' + err)
            )
        //


    }
    //

    //绑定提交
    handleDeptSubmit(event) {
        get_data_token(BIND_APARTMENT, { apartmentId: this.state.selecteddept, unitId: this.state.selectedunit, })
            .then(
                data => {

                    this.getApartment();
                    this.setState({ selectDeptvisible: false, loaddepartment: false, });
                    return;

                })
            .catch(
                err => showErrorMessage.call(this, 'Bind apartment data error:' + err)
            )
        //
        this.setState({
            ModalText: 'You have bound to this apartment already!',
            loaddepartment: false,
        })

    }

    // handleDeptChange(event) {

    //     this.setState({ selecteddept: event.target.value,loaddepartment:false, });


    // }
    componentDidMount() {
        if (localStorage.getItem('accessToken') == null) {
            this.setState({ reload: true });
            return;
        }
        get_data_token(GET_USER_STAT_URL, {})
            .then(data => {
                this.setState({
                    memberinfo: data.member,
                    memberprofile: data.profile,
                    isProfileCompleted: data.member.statusDetail.isProfileCompleted,
                    hasBindAddress: data.member.statusDetail.hasBindAddress,
                })
                //departmentid
                this.getApartment();
                //
                this.getPickList();
            })
            .catch(err => showErrorMessage.call(this, 'Get user statistics error:' + err))
    }
    //
    getApartment() {
        this.setState({ loadapartment: true })
        get_data_token(GET_BIND_APARTMENT, {})
            .then(data => {
                data.apartmentList.map(record => record.key);
                this.setState({ apartments: data.apartmentList, loadapartment: false });
                // console.log("getApartment", data.apartmentList)
            })
            .catch(err => {
                this.setState({ apartments: [], loadapartment: false });
                showErrorMessage.call(this, 'Get apartment error:' + err);
            })
    }
    //
    getPickList() {
        this.setState({ load: true })
        get_data_token(GET_PICK_LIST, {})
            .then(data => {
                data.storeList.map(record => record.key);
                this.setState({ records: data.storeList, load: false });
                // console.log("getpicklist", data)
            })
            .catch(err => {
                this.setState({ records: [], load: false });
                showErrorMessage.call(this, 'Get pickuplist error:' + err);
            })
    }
    //
    render() {
        //

        //
        const memberinfo = this.state.memberinfo;
        const memberprofile = this.state.memberprofile;
        let completeinfo;
        if (Object.keys(memberinfo).length === 0) { completeinfo = "Not complete profile"; }
        else { completeinfo = (memberinfo.statusDetail.isProfileCompleted === 1) ? "Complete profile" : "Not complete profile"; }
        return (
            <div >
                <div className="main">
                    <AccountHeader page={"Account"}
                        title={<FormattedMessage id="page.sidnav.title.Dashboard" defaultMessage="Dashboard" />}
                    />

                    <div className="main-body">
                        <Row gutter={16}>
                            <Col span={1}>
                            </Col>

                            <Card title="User Info" size="default" bordered={false}>
                                <Descriptions

                                    title=""
                                    bordered
                                    column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                                >

                                    <Descriptions.Item label="Email" >{memberinfo.email} </Descriptions.Item>
                                    <Descriptions.Item label="Nick Name" >{memberprofile.nickName} </Descriptions.Item>
                                    <Descriptions.Item label="Phone" >{memberinfo.phone}</Descriptions.Item>
                                    <Descriptions.Item label="Status" >
                                        {completeinfo}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="FirstName">{memberprofile.firstName}</Descriptions.Item>
                                    <Descriptions.Item label="LastName">{memberprofile.lastName}</Descriptions.Item>
                                    <Descriptions.Item label="Address" >{memberprofile.addressline1}<br />
                                        {memberprofile.addressline2 === '' ? null : memberprofile.addressline2} <br /> {memberprofile.city + "  "}  {memberprofile.state + " "}  {memberprofile.zipcode}</Descriptions.Item>
                                    <Descriptions.Item label="Householder" >{memberprofile.householderMember} </Descriptions.Item>

                                </Descriptions>
                                <p />
                                <p />
                                <p align='right'><Link to="/account/profile" Class="pull-right title-link"><Button type='primary' size='large'>Update Profile</Button></Link></p>
                            </Card>
                            <Card title="Subscribe Apartment" size="default" bordered={false}>
                                {this.renderApartments()}
                                <p align='right'>
                                    <Button style={{ marginRight: 8 }} type='primary' size='large' onClick={this.cancelshowModal} >Unsubscribe Apartment</Button>
                                    <Button style={{ marginRight: 8 }} type='primary' size='large' onClick={this.showModal}>Subscribe Apartment</Button>
                                </p>
                                {this.renderZipcode()}
                                {this.renderDepartment()}
                                {this.rendercancelbind()}
                            </Card>
                            <Card title="Your packages" size="default" bordered={false}>

                                {this.renderRecords()}


                            </Card>

                            <Card title="Transaction History" size="default" bordered={false}>
                                <p align='right'><Link to="/account/transactions" Class="pull-right title-link"><Button type='primary' size='large'>Transactions</Button></Link></p>
                            </Card>


                        </Row>
                    </div>
                    {ModalBox.call(this)}

                </div>

            </div>
        );
    }
}

export default Account;