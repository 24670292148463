import React, { Component } from 'react';
import './Account.css'
import './SideNav.css'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl';
import { showErrorMessage } from '../MessageBox';

class SideNav extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    getRootClass() {
        if (this.props.sidenav_show === true) {
            return "sidenav sidenave-show";
        }
        else return "sidenav";
    }
    // render_recharge() {
    //     if (process.env.REACT_APP_LOCATION === 'CHINESE') return;
    //     return (
    //         <div>
    //             <Link className={this.props.page === 'Recharge' ? "current" : null} to="/account/recharge"><i class="fa fa-dollar"></i>
    //                 {' '} <FormattedMessage id="page.sidnav.title.Recharge" defaultMessage="Recharge" />

    //             </Link>
    //             <Link className={this.props.page === 'RechargeRecords' ? "current" : null} to="/account/rechargerecords"><i class="fa fa-list"></i>
    //                 {' '} <FormattedMessage id="page.sidnav.title.RechargeRecords" defaultMessage="Recharge Records" />
    //             </Link>
    //         </div>
    //     )
    // } 
    render() {
        let isprofile = localStorage.getItem('isProfileCompleted');
        if (isprofile === '1') {
            return (
                <div className={this.getRootClass()}>
                    <div className="side-header">
                        <Link to={"/"}><img alt='' className="brand-img" src={require("../../img/zipcodexpress_logo.png")} /></Link>
                        {
                            this.props.sidenav_show === true ?
                                <a href="javascript:void(0)" class="closebtn" onClick={this.props.toggle}>&times;</a>
                                : null
                        }
                    </div>
                    <div className="side-link">
                        <Link className={this.props.page === 'Account' ? "current" : null} to="/account/dashboard"><i class="fa fa-dashboard"></i>
                            {' '} <FormattedMessage id="d" defaultMessage="Dashboard" />
                        </Link>
                        {/* {this.render_recharge()} */}
                        <Link className={this.props.page === 'Profile' ? "current" : null} to="/account/profile"><i class="fa fa-user"></i>
                            {' '} <FormattedMessage id="page.sidnav.title.Profile" defaultMessage="Profile" />

                        </Link>
                        <Link className={this.props.page === 'Transactions' ? "current" : null} to="/account/Transactions"><i class="fa fa-list"></i>
                            {' '} <FormattedMessage id="page.sidnav.title.Transactions" defaultMessage="Transactions" />
                        </Link>

                        <Link className={this.props.page === 'ChangePassword' ? "current" : null} to="/account/changepassword"><i class="fa fa-key"></i>
                            {' '} <FormattedMessage id="page.sidnav.title.ChangePassword" defaultMessage="Change Password" />

                        </Link>
                        <Link className={this.props.page === 'Support' ? "current" : null} to="/account/support"><i class="fa fa-question"></i>
                            {' '} <FormattedMessage id="page.sidnav.title.Support" defaultMessage="Support" />

                        </Link>
                        {/*
                    <Link to="/productselect"><i class="fa fa-product-hunt"></i>
                        {' '} <FormattedMessage id="page.sidnav.title.product" defaultMessage="Rent Product" />

                    </Link>
                    */}

                        <Link className="logout" to="/logout"><i class="fa fa-sign-out"></i>
                            {' '} <FormattedMessage id="page.logout" defaultMessage="Logout" />

                        </Link>
                    </div>
                </div>
            );
        }
        else
        //未完善信息
        {
            //showErrorMessage.call(this, 'Please complete your profile');
            return (
                <div className={this.getRootClass()}>
                    <div className="side-header">
                        <Link to={"/"}><img alt='' className="brand-img" src={require("../../img/zipcodexpress_logo.png")} /></Link>
                        {
                            this.props.sidenav_show === true ?
                                <a href="javascript:void(0)" class="closebtn" onClick={this.props.toggle}>&times;</a>
                                : null
                        }
                    </div>
                    <div className="side-link">
                        <Link className={this.props.page === 'Account' ? "current" : null} to="/account/profile"><i class="fa fa-dashboard"></i>
                            {' '} <FormattedMessage id="d" defaultMessage="Dashboard" />
                        </Link>
                        {/* {this.render_recharge()} */}
                        <Link className={this.props.page === 'Profile' ? "current" : null} to="/account/profile"><i class="fa fa-user"></i>
                            {' '} <FormattedMessage id="page.sidnav.title.Profile" defaultMessage="Profile" />

                        </Link>
                        <Link className={this.props.page === 'Transactions' ? "current" : null} to="/account/profile"><i class="fa fa-list"></i>
                            {' '} <FormattedMessage id="page.sidnav.title.Transactions" defaultMessage="Transactions" />
                        </Link>

                        <Link className={this.props.page === 'ChangePassword' ? "current" : null} to="/account/profile"><i class="fa fa-key"></i>
                            {' '} <FormattedMessage id="page.sidnav.title.ChangePassword" defaultMessage="Change Password" />

                        </Link>
                        <Link className={this.props.page === 'Support' ? "current" : null} to="/account/profile"><i class="fa fa-question"></i>
                            {' '} <FormattedMessage id="page.sidnav.title.Support" defaultMessage="Support" />

                        </Link>
                        {/*
                    <Link to="/productselect"><i class="fa fa-product-hunt"></i>
                        {' '} <FormattedMessage id="page.sidnav.title.product" defaultMessage="Rent Product" />

                    </Link>
                    */}

                        <Link className="logout" to="/logout"><i class="fa fa-sign-out"></i>
                            {' '} <FormattedMessage id="page.logout" defaultMessage="Logout" />

                        </Link>
                    </div>
                </div>
            );
        }

    }
}

export default SideNav;