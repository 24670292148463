import React, { Component } from 'react';
import './Account.css'
import AccountHeader from './AccountHeader';
import {
  
    get_data_token,
    GET_STORE_LIST,

} from '../../config/network'
import { showErrorMessage, ModalBox,  } from '../MessageBox';
import { Col, Button,  FormGroup,  Input,Table, } from 'reactstrap';
import {  Tabs,} from 'antd';
const { TabPane } = Tabs;

function callback(key) {
    // console.log(key);
 }   

class Transactions extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            startDate: this.getInitStartTimeStr(),
            endDate: this.formatDate(new Date()),
            load:false,
            Confirmvisible:false,
        };
        
        this.getTransactions = this.getTransactions.bind(this);
        this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
        this.handleChangeEndTime = this.handleChangeEndTime.bind(this);
        this.checkDateInput = this.checkDateInput.bind(this);

    }

    componentDidMount() {
        this.getTransactions();

    }

    //
    getTransactions() {

         this.setState({load:true})
            get_data_token( GET_STORE_LIST,{
            startime: new Date(this.state.startDate).getTime() / 1000,
            endtime: new Date(this.state.endDate).getTime() / 1000,
           })
            .then(data => {
                 data.storeList.map(record => record.key);
                 this.setState({ records: data.storeList,load:false });
                // console.log("getTransactions", data.storeList)
            })
             .catch(
                 err => {
                 this.setState({ records: [],load:false });
                 //showErrorMessage.call(this, 'Get transactions error:' + err);
             })

    }
    getInitStartTimeStr() {

        let d = new Date(Date.now() - 1000 * 86400 * 30);
        return this.formatDate(d);
    }
    formatDate(date) {
        
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
    checkDateInput(dateStr){
        if(dateStr.search(/^\d{4}-\d{2}-\d{2}$/)===-1) {
            showErrorMessage.call(this,'Please input right date format: yyyy-mm-dd')
            return false;
        }
  
        return true;
    }
    handleChangeStartTime(event) {
        if(!this.checkDateInput(event.target.value))
            return;
        this.setState({
            startDate: event.target.value
        });
    }
    handleChangeEndTime(event) {
        if(!this.checkDateInput(event.target.value))
            return;
        this.setState({
            endDate: event.target.value
        });
    }

    
    //
    renderRecords() {
        if (this.state.load) 
            return <h5>Loading...</h5>;
        if (this.state.records.length === 0) 
            return <p>You have no package.</p>;
        return (
            <Table>
                <thead>
                    <tr>             
                            <th>Store Id</th>
                            <th>Cabinet Id</th>
                            <th>Pickup Code</th>
                            <th>Store Time</th>
                            <th>Pickup Time</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.records.map(record =>
                            <tr>
                               <td>{record.storeId}</td>  
                               <td>{record.cabinetId}</td>  
                               <td>{record.pickCode}</td>  
                               <td>{record.storeTime}</td>  
                               <td>{record.pickTime}</td>  
                          </tr>
                        )
                    }
                </tbody>
            </Table>
        )
    }
    render() {
        return (
            <div >
                <div className="main">
                    <AccountHeader page={"Transactions"} title="Transactions" />
                    <div className="main-body">

                        {/* <Tabs defaultActiveKey="1" onChange={callback} > */}
                          <Tabs defaultActiveKey="1" onChange={callback} > 
                              <TabPane tab="History" key="1">
                                 <p></p> 
                                 <FormGroup row>
                                 <Col sm={4}>
                                  {/* <DatePicker selected={this.state.startDate} onChange={this.handleChangeStartTime}/> */}
                                    <Input type='date' value={this.state.startDate} onChange={this.handleChangeStartTime} />
                                    </Col>
                                    <Col sm={4}>
                                    {/* <DatePicker selected={this.state.endDate} onChange={this.handleChangeEndTime}/> */}
                                       <Input type='date' value={this.state.endDate} onChange={this.handleChangeEndTime} />
                                  </Col>
                                  <Col sm={4}>
                                    {/*
                                     <Button color="primary"
                                     onClick={this.getTransactions}><i class="fa fa-book"></i></Button>
                                    */}
                                    <Button variant="contained" color="primary" onClick={this.getTransactions}>
                                          Search
                                    </Button>
                                  </Col>
                                  </FormGroup>                               
                                  {this.renderRecords()}                           
                              </TabPane>

                         </Tabs>
                    </div>
                </div>
                {ModalBox.call(this)}

            </div>
        );
    }
}

export default Transactions;