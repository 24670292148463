import React, { Component } from 'react';
import Header from './Header'
import Footer1 from './Footer'
import './Pricing.css'


class UserAgreement extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className='myContainer'>
                <Header page='UserAgreement' />
                <section>
                    <div className="mycontainer">
                        <p>&nbsp;</p>
                        <h1><strong>Terms and Conditions</strong></h1>
                        <p>&nbsp;</p>
                        <p>Version Date: 01/08/2024</p>
                        <p>&nbsp;</p>
                        <p><strong>TERMS OF SERVICE AGREEMENT</strong></p>
                        <p>&nbsp;</p>
                        <p>This Terms and Conditions Agreement (&ldquo;Agreement&rdquo;) constitutes a legally binding agreement made between you, whether personally or on behalf of an entity (&ldquo;user&rdquo; or &ldquo;you&rdquo;) and ZipcodeXpress and its affiliated companies (collectively, &ldquo;ZipcodeXpress&rdquo; or &ldquo;we&rdquo; or &ldquo;us&rdquo; or &ldquo;our&rdquo;), concerning your access to and use of the ZipcodeXpress service. You agree that by using this service, you have read, understood, and agree to be bound by all of these Terms and Conditions. If you do not agree with all of these Terms and Conditions, then you are expressly prohibtied from using the service and you must discontinue the use immediately.</p>
                        <p>&nbsp;</p>
                        <p>ZipcodeXpress makes no representation that the Service is appropriate or available in other locations other than where it is operated by ZipcodeXpress. The information provided on the Service is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject ZipcodeXpress to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Service from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>All users who are minors in the jurisdiction in which they reside (generally under the age of 18) are not permitted to register an account to use the ZipcodeXpress Service.</p>
                        <p>&nbsp;&nbsp;</p>
                        <p><strong>USER</strong> <strong>REGISTRATION</strong></p>
                        <p><strong><em>&nbsp;</em></strong></p>
                        <p>By using the ZipcodeXpress Services, you must create a ZipcodeXpress account by registering at the ZipcodeXpress Service on website.</p>
                        <p>&nbsp;</p>
                        <p>By registering your account, you represent and warrant that:</p>
                        <p>&nbsp;</p>
                        <p>A. all registration information you submit is truthful and accurate;</p>
                        <p>B. you will maintain the accuracy of such information;</p>
                        <p>C. you will keep your password confidential and will be responsible for all use of your password and account;</p>
                        <p>D. you are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use this Service; and</p>
                        <p>E. your use of the ZipcodeXpress Services does not violate any applicable law or regulation, include local, state, national, and international laws and regulations;</p>
						<p>F. By subscribing to our package locker delivery service, you agree to receive delivery notifications related to your parcel. These notifications will keep you informed about the status of your delivery, including alerts when your package is placed in the locker and reminders for pickup.</p>
                        <p>&nbsp;</p>
                        <p>You also agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the Service&rsquo;s registration form and (b) maintain and promptly update registration data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or&nbsp;ZipcodeXpress has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete,&nbsp;ZipcodeXpress has the right to suspend or terminate your account and refuse any and all current or future use of the Service (or any portion thereof).&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;<strong>PURCHASES; PAYMENT</strong></p>
                        <p>&nbsp;</p>
                        <p>Certain aspects of the Services are, or may in the future be, provided for a fee or other charge. In the event you choose to use paid aspects of the Services, you agree to pay ZipcodeXpress all charges at the prices then in effect for the products you or other persons using your billing account may purchase, and you authorize ZipcodeXpress to charge your chosen payment provider for any such purchases. You agree to make payment using that selected payment method. If you have ordered a product or service that is subject to recurring charges then you consent to our charging your payment method on a recurring basis, without requiring your prior approval &nbsp;from you for each recurring charge until such time as you cancel the applicable product or service. ZipcodeXpress reserves the right to correct any errors or mistakes in pricing that it makes even if it has already requested or received payment. Sales tax will be added to the sales price of purchases as deemed required by ZipcodeXpress. ZipcodeXpress may change prices at any time. All payments shall be in U.S. dollars.&nbsp;</p>
                        <p>&nbsp;&nbsp;<strong>&nbsp;</strong>&nbsp;</p>
                        <p><strong>INTELLECTUAL PROPERTY RIGHTS</strong></p>
                        <p>&nbsp;</p>
                        <p>The content on the Service (&ldquo;ZipcodeXpress Content&rdquo;) and the trademarks, service marks and logos contained therein (&ldquo;Marks&rdquo;) are owned by or licensed to ZipcodeXpress, and are subject to copyright and other intellectual property rights under United States and foreign laws and international conventions. ZipcodeXpress Content, includes, without limitation, all source code, databases, functionality, software, Service designs, audio, video, text, photographs and graphics. All ZipcodeXpress graphics, logos, designs, page headers, button icons, scripts and service names are registered trademarks, common law trademarks or trade dress of ZipcodeXpress in the United States and/or other countries. ZipcodeXpress's trademarks and trade dress may not be used, including as part of trademarks and/or as part of domain names, in connection with any product or service in any manner that is likely to cause confusion and may not be copied, imitated, or used, in whole or in part, without the prior written permission of the ZipcodeXpress.</p>
                        <p>&nbsp;</p>
                        <p>ZipcodeXpress Content on the Service is provided to you &ldquo;AS IS&rdquo; for your information and personal use only and may not be used, copied, reproduced, aggregated, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the prior written consent of the respective owners. Provided that you are eligible to use the Service, you are granted a limited license to access and use the Service and the ZipcodeXpress Content and to download or print a copy of any portion of the ZipcodeXpress Content to which you have properly gained access solely for your personal, non-commercial use. ZipcodeXpress reserves all rights not expressly granted to you in and to the Service and ZipcodeXpress Content and Marks.</p>
                        <p>&nbsp;</p>
                        <p><strong>THIRD PARTY SITES, PRODUCTS AND SERVICES </strong></p>
                        <p>&nbsp;</p>
                        <p>The Services may contain links to third party websites, applications, products, and services that are offered and/or maintained by others (collectively, "Third Party Services"). Any such links are provided solely as a convenience to you and not as an endorsement by ZipcodeXpress of the Third Party Services.&nbsp; ZipcodeXpress is not responsible for such Third Party Services and does not make any representations or warranties regarding such Third Party Services or the privacy practices of such third parties. If you decide to access such Third Party Services, you do so at your own risk.</p>
                        <p>&nbsp;<strong>&nbsp;</strong></p>
                        <p><strong>ZipcodeXpress MANAGEMENT </strong></p>
                        <p>&nbsp;</p>
                        <p>ZipcodeXpress reserves the right but does not have the obligation to:</p>
                        <p>&nbsp;</p>
                        <p>A. investigate violations of this Agreement;</p>
                        <p>B. take appropriate legal action against anyone who, in ZipcodeXpress&rsquo;s sole discretion, violates this Agreement, including without limitation, reporting such user to law enforcement authorities;</p>
                        <p>C. in ZipcodeXpress&rsquo;s sole discretion and without limitation, refuse, restrict access to or availability of, or disable (to the extent technologically feasible) any user&rsquo;s contribution or any portion thereof that may violate this Agreement or any ZipcodeXpress policy;</p>
                        <p>D. in&nbsp;ZipcodeXpress&rsquo;s sole discretion and without limitation, notice or liability to suspend or terminates anyone&rsquo;s use of the Service and the Services.</p>
                        <p>E. otherwise manage the Service and the Services in a manner designed to protect the rights and property of ZipcodeXpress and others and to facilitate the proper functioning of the Service and the Services.</p>
                        <p>&nbsp;</p>
                        <p><strong>TERM AND TERMINATION</strong></p>
                        <p>&nbsp;</p>
                        <p>This Agreement shall remain in full force and effect while you use the Service. You may terminate your use or participation at any time, for any reason, by following the instructions for terminating user accounts in your account settings, if available, or by contacting us.</p>
                        <p>&nbsp;</p>
                        <p>WITHOUT LIMITING ANY OTHER PROVISION OF THIS AGREEMENT, ZipcodeXpress RESERVES THE RIGHT TO, IN ZipcodeXpress&rsquo;S SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICE AND THE ZipcodeXpress SERVICES, TO ANY PERSON FOR ANY REASON OR FOR NO REASON AT ALL, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY OR COVENANT CONTAINED IN THIS AGREEMENT, OR OF ANY APPLICABLE LAW OR REGULATION, AND ZipcodeXpress MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICE AND THE ZipcodeXpress SERVICES, DELETE YOUR PROFILE AND ANY CONTENT OR INFORMATION THAT YOU HAVE POSTED AT ANY TIME, WITHOUT WARNING, IN ZipcodeXpress&rsquo;S SOLE DISCRETION.</p>
                        <p>&nbsp;&nbsp;</p>
                        <p>If ZipcodeXpress terminates or suspends your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, ZipcodeXpress reserves the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>
                        <p>&nbsp;</p>
                        <p><strong>MODIFICATIONS</strong></p>
                        <p>&nbsp;<em>&nbsp;</em></p>
                        <p>ZipcodeXpress may modify this Agreement from time to time. Any and all changes to this Agreement will be posted on the Service and revisions will be indicated by date. You agree to be bound to any changes to this Agreement when you use the ZipcodeXpress Services after any such modification becomes effective. ZipcodeXpress may also, in its discretion, choose to alert all users with whom it maintains email information of such modifications by means of an email to their most recently provided email address. It is therefore important that you regularly review this Agreement and keep your contact information current in your account settings to ensure you are informed of changes. You agree that you will periodically check the Service for updates to this Agreement and you will read the messages we send you to inform you of any changes. Modifications to this Agreement shall be effective after posting.</p>
                        <p>&nbsp;</p>
                        <p><strong>CORRECTIONS</strong></p>
                        <p>&nbsp;</p>
                        <p>Occasionally there may be information on the Service that contains typographical errors, inaccuracies or omissions that may relate to service descriptions, pricing, availability, and various other information. ZipcodeXpress reserves the right to correct any errors, inaccuracies or omissions and to change or update the information at any time, without prior notice.</p>
                        <p>&nbsp;</p>
                        <p><strong>LIMITATIONS OF LIABILITY</strong></p>
                        <p>&nbsp;</p>
                        <p>IN NO EVENT SHALL ZipcodeXpress OR ITS DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICE OR ZipcodeXpress SERVICES, EVEN IF ZipcodeXpress HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, ZipcodeXpress&rsquo;S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO ZipcodeXpress FOR THE ZipcodeXpress SERVICES DURING THE PERIOD OF 3 PRIOR TO ANY CAUSE OF ACTION ARISING.</p>
                        <p>&nbsp;</p>
                        <p>CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS SERVICELY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT SERVICELY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>
                        <p>&nbsp;<strong>&nbsp;</strong></p>
                        <p><strong>USER DATA</strong></p>
                        <p><strong>&nbsp;</strong></p>
                        <p>Our Service will maintain certain data that you transfer to the Service for the purpose of the performance of the ZipcodeXpress Services, as well as data relating to your use of the ZipcodeXpress Services. Although we perform regular routine backups of data, you are primarily responsible for all data that you have transferred or that relates to any activity you have undertaken using the ZipcodeXpress Services.&nbsp; You agree that ZipcodeXpress shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against ZipcodeXpress arising from any such loss or corruption of such data.</p>
                        <p><strong>&nbsp;&nbsp;&nbsp;</strong></p>
                        <p><strong>MISCELLANEOUS</strong></p>
                        <p>&nbsp;</p>
                        <p>This Agreement constitutes the entire agreement between you and ZipcodeXpress regarding the use of the ZipcodeXpress Services. The failure of ZipcodeXpress to exercise or enforce any right or provision of this Agreement shall not operate as a waiver of such right or provision. The section titles in this Agreement are for convenience only and have no legal or contractual effect. This Agreement operates to the fullest extent permissible by law. This Agreement and your account may not be assigned by you without our express written consent. ZipcodeXpress may assign any or all of its rights and obligations to others at any time. ZipcodeXpress shall not be responsible or liable for any loss, damage, delay or failure to act caused by any cause beyond ZipcodeXpress's reasonable control.&nbsp; If any provision or part of a provision of this Agreement is unlawful, void or unenforceable, that provision or part of the provision is deemed severable from this Agreement and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and ZipcodeXpress as a result of this Agreement or use of the Service and ZipcodeXpress Services. Upon ZipcodeXpress&rsquo;s request, you will furnish ZipcodeXpress any documentation, substantiation or releases necessary to verify your compliance with this Agreement. You agree that this Agreement will not be construed against ZipcodeXpress by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of this Agreement and the lack of signing by the parties hereto to execute this Agreement</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>

                    </div>
                </section>
                <Footer1 />
            </div>

        );
    }
}

export default UserAgreement;