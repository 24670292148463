import React, { Component } from 'react';
import './Footer.css'

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <footer >
                <p/>
                <p>ZipcodeXpress Express Cabinet Management, Copyright &copy; 2021</p>
            </footer>
        );
    }
}

export default Footer;